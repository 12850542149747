@import '../../../../../projects/ui/src/lib/scss/themes/smooved/variables';
@import '../../../../../projects/ui/src/lib/scss/base/mixins';

app-payed-invoice {
    app-alert {
        i {
            color: $color-info-dark;
        }

        .m-alert {
            height: auto;
        }
    }

    .__flex-dynamic {
        display: flex;
        flex-direction: row;

        @include tablet-landscape-up {
            flex-direction: column;
        }

        @include desktop-up {
            flex-direction: row;
        }
    }
}
