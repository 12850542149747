@import '../../../../../projects/ui/src/lib/scss/themes/smooved/variables';
@import '../../../../../projects/ui/src/lib/scss/base/mixins';

app-invoice {
    .__info {
        width: 100%;
        max-width: $container-extra-small-max-width;
    }

    app-alert {
        i {
            color: $color-info-dark;
        }

        .m-alert {
            height: auto;
        }
    }

    .__total-amount-card {
        align-self: end;
    }

    .__upload-complete-icon {
        width: 24px;
        height: 24px;
        padding-bottom: 5px;

        path {
            fill: $color-white;
        }
    }
}
