import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DocumentCenterSandbox } from '@app/document-center/document-center.sandbox';
import { MoveUtils } from '@app/move/state/move.utils';
import { AppI18nKeyType } from '@app/shared/constants/i18n-key-type-map';
import { Move } from '@app/move/interfaces/move';
import { DbUtils } from '@smooved/core';
import { ButtonAppearance, ButtonSize } from '@smooved/ui';
import { EnergyStop } from '@app/services/energy-stop/classes/energy-stop';
import { ServiceStatus } from '@app/services/enums/service-status.enum';

@Component({
    selector: 'app-todo-leaver-regularization-bill-requested',
    templateUrl: './todo-leaver-regularization-bill-requested.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TodoLeaverRegularizationBillRequestedComponent {
    @Input() public move: Move;
    @Input() public energyStop: EnergyStop;

    public readonly buttonAppearance = ButtonAppearance;
    public readonly buttonSize = ButtonSize;
    public readonly i18nKeys = AppI18nKeyType;

    constructor(private readonly documentCenter: DocumentCenterSandbox) {}

    public downloadEDS(): void {
        const { leaver } = MoveUtils.getMovers(this.move);
        this.documentCenter.downloadEnergyDocumentSummary(DbUtils.getStringId(leaver));
    }

    protected readonly ServiceStatus = ServiceStatus;
}
