import { AppI18nKeyType, i18nKeyTypeMap } from '@app/shared/constants/i18n-key-type-map';
import { I18nKeyUtils } from '@smooved/core';

const baseI18n = 'DETAIL.WATER_DOCUMENTS_MOVING_ADDRESS_BY_REAL_ESTATE_AGENT';
export const labelCheckedCollectiveMeterI18n = map(I18nKeyUtils.join(baseI18n, 'WATER_COLLECTIVE_METER'));
export const labelCheckedDefaultMeterI18n = map(I18nKeyUtils.join(baseI18n, 'OK'));
export const labelCtaCollectiveMeterI18n = map(I18nKeyUtils.join(baseI18n, 'WATER_COLLECTIVE_METER.CTA'));

function map(key: string): string {
    return I18nKeyUtils.map(i18nKeyTypeMap, AppI18nKeyType.RealEstateAgentDashboard, key);
}
