import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AppI18nKeyType } from '@app/shared/constants/i18n-key-type-map';
import { TodoState } from '@smooved/ui';

@Component({
    selector: 'smvd-app-todo-leaver-not-interested',
    templateUrl: 'todo-leaver-not-interested.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TodoLeaverNotInterestedComponent {
    public readonly todoState = TodoState;
    public readonly i18nKeys = AppI18nKeyType;
}
