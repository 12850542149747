import { Component, Input } from '@angular/core';
import { Svg } from '@smooved/ui';
import { Move } from '../../../move/interfaces/move';

@Component({
    selector: 'app-service-water-by-admin',
    template: `
        <div class="u-flex-row u-flex-align-items-center" [ngSwitch]="checked" [ngClass]="{ 'u-color-muted': !checked }">
            <app-check-with-background width="20" height="20" *ngSwitchCase="true" class="u-margin-right-half"></app-check-with-background>

            <div class="u-flex-row u-flex-align-items-start">
                <app-svg [name]="emptySvg" *ngSwitchCase="false" width="24" height="24" class="u-margin-right-half"></app-svg>

                <span>{{ 'REAL_ESTATE_AGENT.DASHBOARD.DETAIL.WATER_TRANSFER.FOLLOW_UP.PROCESSED_BY_SMOOVED' | translate }}</span>
            </div>
        </div>
    `,
    styles: [],
})
export class ServiceWaterByAdminComponent {
    @Input() public move: Move;
    @Input() public checked: boolean;

    public emptySvg = Svg.Empty;
}
