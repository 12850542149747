import { ExternalInfluencerConfigureContainer } from '@app/external-influencer/containers/external-influencer-configure/external-influencer-configure.container';
import { AppFeatureScopeGuard } from '@app/feature-scope/guards/app-feature-scope-guard.service';
import { FeatureScope, LogicalOperation, NavigationData } from '@smooved/core';
import { Navigation, NavigationOutlet } from '@app/navigation/enums/navigation.enum';
import { FeatureDetailGiftsContainer } from '@app/real-estate-agent/containers/feature-detail-gifts/feature-detail-gifts.container';
import { FeatureDetailInsurancesContainer } from '@app/real-estate-agent/containers/feature-detail-insurances/feature-detail-insurances.container';
import { FeatureDetailMortgageContainer } from '@app/real-estate-agent/containers/feature-detail-mortgage/feature-detail-mortgage.container';
import { FeatureDetailReviewsContainer } from '@app/real-estate-agent/containers/feature-detail-reviews/feature-detail-reviews.container';
import { FeatureDetailTotsContainer } from '@app/real-estate-agent/containers/feature-detail-tots/feature-detail-tots.container';
import { FeatureDetailWaterContainer } from '@app/real-estate-agent/containers/feature-detail-water/feature-detail-water.container';
import { Route } from '@angular/router';
import { SettingsDetailsMenuComponent } from '@app/real-estate-agent/components/settings-details-menu/settings-details-menu.component';

export const modulesRouting: Route = {
    path: Navigation.Modules,
    children: [
        {
            path: Navigation.Reviews,
            component: FeatureDetailReviewsContainer,
            children: [
                {
                    path: Navigation.Configure,
                    component: ExternalInfluencerConfigureContainer,
                },
            ],
        },
        {
            path: Navigation.Gifts,
            component: FeatureDetailGiftsContainer,
        },
        {
            path: Navigation.Mortgage,
            component: FeatureDetailMortgageContainer,
            canActivate: [AppFeatureScopeGuard],
            data: {
                [NavigationData.FeatureScopeConfig]: { scopes: [FeatureScope.Mortgage], operator: LogicalOperation.And },
            },
        },
        {
            path: Navigation.Insurances,
            component: FeatureDetailInsurancesContainer,
        },
        {
            path: Navigation.Water,
            component: FeatureDetailWaterContainer,
        },
        {
            path: Navigation.Tots,
            component: FeatureDetailTotsContainer,
        },
        {
            path: '',
            pathMatch: 'full',
            redirectTo: Navigation.Reviews,
        },
        {
            path: '**',
            pathMatch: 'full',
            redirectTo: Navigation.Reviews,
        },

        {
            path: '',
            component: SettingsDetailsMenuComponent,
            outlet: NavigationOutlet.Tertiary,
        },
    ],
};
