import { Component, ViewChild } from '@angular/core';
import { ClosableModalTemplateComponent, NotificationSandbox } from '@smooved/ui';
import { base64ToFile, ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { FileUtils } from '@smooved/core';

@Component({
    selector: 'app-image-picker',
    template: `
        <app-closable-modal-template>
            <ng-container modal-header>{{ 'SELECT_IMAGE' | translate }}</ng-container>
            <div class="u-container-small">
                <div class="u-flex u-flex-column u-margin-bottom-half">
                    <smvd-app-input-file (onChange)="fileChangeEvent($event)"></smvd-app-input-file>
                    <span id="file-type-info" class="u-color-gray-dark u-margin-top-half u-font-size-14">{{
                        'IMAGE_PICKER.INFORMATION.FILE_TYPE' | translate: { maxSize: maxSize }
                    }}</span>
                </div>

                <image-cropper
                    [imageChangedEvent]="imageChangedEvent"
                    [maintainAspectRatio]="true"
                    [aspectRatio]="1256 / 314"
                    [resizeToWidth]="1256"
                    [cropperStaticHeight]="314"
                    class="u-margin-bottom"
                    (imageCropped)="imageCropped($event)"
                    (imageLoaded)="imageLoaded($event)"
                    (cropperReady)="cropperReady()"
                    (loadImageFailed)="loadImageFailed()"
                    format="webp"
                ></image-cropper>
            </div>
            <ng-container modal-actions>
                <button app-button (click)="onSelect()">{{ 'SELECT' | translate }}</button>
            </ng-container>
        </app-closable-modal-template>
    `,
    styleUrls: ['./image-picker.modal.scss'],
})
export class ImagePickerModal {
    constructor(private readonly notification: NotificationSandbox) {}

    @ViewChild(ClosableModalTemplateComponent) public closableModalComponent: ClosableModalTemplateComponent;

    imageChangedEvent: any = '';
    croppedImage: any = '';
    maxSize: number = 10; //The maximum allowed size for a cover photo upload in MB

    fileChangeEvent(event: Event): void {
        if ((event.target as HTMLInputElement).files[0].size <= FileUtils.convertMegaBytesToBytes(this.maxSize)) {
            this.imageChangedEvent = event;
        } else {
            this.notification.error('IMAGE_PICKER.EXCEPTION.FILE_SIZE', { maxSize: this.maxSize.toString() });
            (event.target as HTMLInputElement).value = '';
        }
    }

    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64;
    }

    imageLoaded(image: LoadedImage) {
        // show cropper
    }

    cropperReady() {
        // cropper ready
    }

    loadImageFailed() {
        this.notification.error('IMAGE_PICKER.EXCEPTION.FILE_TYPE');
        if (this.imageChangedEvent?.target?.value) this.imageChangedEvent.target.value = '';
    }

    public onSelect(): void {
        this.closableModalComponent.close(base64ToFile(this.croppedImage));
    }
}
