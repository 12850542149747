import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MoverRole } from '@app/move/enums/mover-role.enum';
import { Move } from '@app/move/interfaces/move';
import { RealEstateAgentContactLogService } from '@app/real-estate-agent/services/real-estate-agent-contact-log.service';
import { RealEstateAgentModalsSandbox } from '@app/real-estate-agent/services/real-estate-agent-modals.sandbox';
import { appI18nKeyTypes } from '@app/shared/constants/i18n-key-type-map';
import { ButtonAppearance, ButtonSize, UiContext } from '@smooved/ui';
import { EnergyStop } from '@app/services/energy-stop/classes/energy-stop';
import { EnergyStopSuppliersStatus } from '@app/services/energy-stop/enums/energy-stop-suppliers-status';

@Component({
    selector: 'app-todo-leaver-suppliers-received',
    templateUrl: './todo-leaver-suppliers-received.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TodoLeaverSuppliersReceivedComponent {
    @Input() public move: Move;
    @Input() public energyStop: EnergyStop;

    public readonly i18nKeys = appI18nKeyTypes;
    public readonly moverRole = MoverRole;
    public readonly buttonAppearance = ButtonAppearance;
    public readonly buttonSize = ButtonSize;
    public readonly uiContext = UiContext;

    constructor(
        private readonly realEstateAgentContactLogService: RealEstateAgentContactLogService,
        private readonly realEstateAgentModalSandbox: RealEstateAgentModalsSandbox
    ) {}

    public resendInvite(): void {
        this.realEstateAgentContactLogService.resendLeaverCollectionInvite(this.move).subscribe();
    }

    public updateSuppliers(): void {
        this.realEstateAgentModalSandbox.openUpdateLeaverSuppliersModal(this.move);
    }

    protected readonly EnergyStopSuppliersStatus = EnergyStopSuppliersStatus;
}
