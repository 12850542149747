<app-two-panel-modal [hasSidePanel]="hasSidePanel">
    <h5 modal-header>{{ 'REVIEWS.SIGNATURE.CONFIGURE.LABEL' | translate }}</h5>
    <div modal-left>
        <div class="widget-viewer u-padding-double u-margin-bottom-double" *ngIf="theme$ | async as theme">
            <div class="u-w100p u-h100p u-background-white">
                <app-copy-to-clipboard class="u-display-block u-padding-double" [nocopy]="true">
                    <table style="border-collapse: collapse">
                        <tbody>
                            <tr>
                                <td valign="center">
                                    <div
                                        [style.borderColor]="form.get(formFields.profile).value || form.get(formFields.info).value ? borderColor : transparent"
                                        style="border-right: 1px solid; padding-right: 32px; display: inline-block"
                                    >
                                        <a [href]="externalInfluencerUrl" target="_blank"><img [src]="signatureSource" height="150" /></a>
                                    </div>
                                </td>
                                <td valign="center" *ngIf="form.get(formFields.info).value">
                                    <div style="padding-left: 32px; display: inline-block">
                                        <strong>Sjef Mooved</strong><br />
                                        <span style="padding: 0" [style.color]="theme.linksColor">Smooved Mascotte</span><br />
                                        <ng-container *ngIf="externalInfluencer.phoneNumber">
                                            <a
                                                [style.color]="theme.accentBackground"
                                                [style.padding]="0"
                                                [href]="externalInfluencer.phoneNumber | uriSchema: uriSchemaType.Tel"
                                                style="padding: 0"
                                                >{{externalInfluencer.phoneNumber}}</a
                                            ><br />
                                        </ng-container>
                                        <a
                                            [style.color]="theme.accentBackground"
                                            [href]="externalInfluencer.email | uriSchema: uriSchemaType.MailTo"
                                            [style.padding]="0"
                                            >{{externalInfluencer.email}}</a
                                        ><br />
                                        <ng-container *ngIf="externalInfluencer.website">
                                            <a
                                                [style.color]="theme.accentBackground"
                                                [style.padding]="0"
                                                [href]="externalInfluencer.website"
                                                target="_blank"
                                                >{{externalInfluencer.website | uriSchema: uriSchemaType.Website}}</a
                                            ><br />
                                        </ng-container>
                                        <a
                                            [style.color]="theme.accentBackground"
                                            [style.padding]="0"
                                            [href]="socialsUri.linkedIn + '/' + externalInfluencer.linkedIn"
                                            target="_blank"
                                            *ngIf="externalInfluencer.linkedIn"
                                            >{{socialsUri.linkedIn | uriSchema: uriSchemaType.Website }}/{{externalInfluencer.linkedIn}}</a
                                        >
                                    </div>
                                </td>
                                <td valign="center" *ngIf="form.get(formFields.profile).value">
                                    <div style="padding-left: 32px">
                                        <img
                                            [src]="profileImageUrl"
                                            [style.borderColor]="theme.accentBackground"
                                            class="h-[125px]"
                                            style="border: 1px solid; border-radius: 10rem; display: inline-block;"
                                        />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </app-copy-to-clipboard>
            </div>
        </div>

        <div class="__left-container">
            <form [formGroup]="form">
                <app-select-input
                    class="u-display-block"
                    [label]="'REVIEWS.WIDGET_INFLUENCER_SCORE.CONFIGURE.SELECT_LANGUAGE' | translate"
                    [options]="languageOptions"
                    [custom]="false"
                    [formControlName]="formFields.language"
                    [direction]="direction.Horizontal"
                ></app-select-input>
                <div class="u-flex u-flex-justify-content-space-between u-margin-bottom u-margin-top-double">
                    <div>{{ 'REVIEWS.SIGNATURE.CONFIGURE.TOGGLE_PROFILE_IMAGE' | translate }}</div>
                    <app-slide-toggle [formControlName]="formFields.profile"></app-slide-toggle>
                </div>
                <div class="u-flex u-flex-justify-content-space-between u-margin-bottom">
                    <div>{{ 'REVIEWS.SIGNATURE.CONFIGURE.TOGGLE_CONTACT_INFO' | translate }}</div>
                    <app-slide-toggle [formControlName]="formFields.info"></app-slide-toggle>
                </div>
            </form>

            <div class="__actions">
                <button app-button context="primary" icon="copy" (click)="onCopy($event)" class="__btn-copy u-margin-top-double">
                    {{'REVIEWS.SIGNATURE.CONFIGURE.COPY' | translate }}
                </button>
                <br />
                <button
                    app-button
                    [appearance]="buttonAppearance.Link"
                    icon="chevron_right"
                    (click)="showHowto()"
                    class="u-padding-none u-margin-top"
                >
                    implementatie handleiding
                </button>
            </div>
        </div>
    </div>
    <div modal-right>
        <h6 *collapsible="contentGmail" class="u-color-muted u-margin-bottom-half">
            {{'REVIEWS.SIGNATURE.CONFIGURE.GMAIL.LABEL' | translate }}
        </h6>
        <ng-template #contentGmail>
            <ol class="u-margin-bottom-double">
                <li>
                    <button app-button [appearance]="buttonAppearance.Link" class="u-padding-none" (click)="onCopy($event)">
                        {{'REVIEWS.SIGNATURE.CONFIGURE.GMAIL.STEP1' | translate }}
                    </button>
                </li>
                <li>{{'REVIEWS.SIGNATURE.CONFIGURE.GMAIL.STEP2' | translate }}</li>
                <li>{{'REVIEWS.SIGNATURE.CONFIGURE.GMAIL.STEP3' | translate }}</li>
                <li>{{'REVIEWS.SIGNATURE.CONFIGURE.GMAIL.STEP4' | translate }}</li>
                <li>{{'REVIEWS.SIGNATURE.CONFIGURE.GMAIL.STEP5' | translate }}</li>
            </ol>
        </ng-template>

        <h6 *collapsible="contentOutlook" class="u-color-muted u-margin-bottom-half">
            {{'REVIEWS.SIGNATURE.CONFIGURE.OUTLOOK.LABEL' | translate }}
        </h6>
        <ng-template #contentOutlook>
            <ol>
                <li>
                    <button app-button [appearance]="buttonAppearance.Link" class="u-padding-none" (click)="onCopy($event)">
                        {{'REVIEWS.SIGNATURE.CONFIGURE.OUTLOOK.STEP1' | translate }}
                    </button>
                </li>
                <li>{{'REVIEWS.SIGNATURE.CONFIGURE.OUTLOOK.STEP2' | translate }}</li>
                <li>{{'REVIEWS.SIGNATURE.CONFIGURE.OUTLOOK.STEP3' | translate }}</li>
                <li>{{'REVIEWS.SIGNATURE.CONFIGURE.OUTLOOK.STEP4' | translate }}</li>
                <li>{{'REVIEWS.SIGNATURE.CONFIGURE.OUTLOOK.STEP5' | translate }}</li>
            </ol>
        </ng-template>
    </div>
</app-two-panel-modal>
