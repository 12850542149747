import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ModalModule } from '@app/modal/modal.module';
import { TranslationModule } from '@app/translation/translation.module';
import { ImagePickerModal } from '@app/ui/image/modals/image-picker/image-picker.modal';
import { ButtonModule } from '@smooved/ui';
import { ImageCropperModule } from 'ngx-image-cropper';
import { InputFileComponent } from '../components/input-file/input-file.component';

@NgModule({
    declarations: [ImagePickerModal],
    imports: [CommonModule, ImageCropperModule, ModalModule, TranslationModule, ButtonModule, InputFileComponent],
})
export class ImageModule {}
