@import '../../../../../projects/ui/src/lib/scss/themes/smooved/variables';
@import '../../../../../projects/ui/src/lib/scss/base/mixins';

app-invoices-billing-changes-info {
    app-alert {
        i {
            color: $color-info-dark;
        }
    }
}
