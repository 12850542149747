@import '../../../../../projects/ui/src/lib/scss/themes/smooved/variables';
@import '../../../../../projects/ui/src/lib/scss/base/mixins';

app-dashboard-table {
    @include tablet-portrait-up {
        table.__dashboard-table {
            border-radius: 8px 8px 0 0;
            border: 1px solid var(--color-gray-light);
            border-bottom: 0;
            overflow: hidden;

            tr.mat-mdc-header-row {
                height: 36px;
            }

            tr > th {
                border-bottom: 1px solid var(--color-gray-light);
                font-weight: $font-weight-semi-bold;

                &:first-of-type {
                    border-radius: 8px 0 0 0;
                }

                &:last-of-type {
                    border-radius: 0 8px 0 0;
                }
            }

            td.mat-mdc-cell {
                height: 4rem;
            }
        }

        .mat-mdc-paginator {
            border: 1px solid var(--color-gray-light);
            border-top: 1px solid var(--color-gray-lightest);
            border-radius: 0 0 8px 8px;
        }
    }

    .__cell {
        &.__cell--todo {
            padding: 0 !important;
            text-align: center;
            @include up-to-and-including-phone-landscape {
                width: 30px;
            }
            @include tablet-portrait-up {
                width: 65px;
            }
        }

        &.__cell--name,
        &.__cell--transaction {
            min-width: 140px;
            width: 200px;
        }

        &.__cell--moving-date {
            width: 120px;
        }

        &.__cell--cluster-level {
            width: 55px;
        }

        &.__cell--services {
            width: 100px;
        }

        &.__cell--status {
            @include up-to-and-including-phone-landscape {
                width: 140px;
                text-align: right;
            }
            @include tablet-portrait-up {
                width: 150px;
            }
        }

        &.__cell--energy-meters {
            width: 135px;

            @include desktop-up {
                width: 160px;
            }
        }

        &.__cell--fee {
            width: 110px;
        }

        &.__cell--score {
            width: 60px;
        }

        &.__cell--gift-options,
        &.__cell--energy-status,
        &.__cell--water-status {
            width: 250px;
        }
    }

    .__moving-address {
        max-width: 200px;
    }

    .__container--address {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;

        app-address-output {
            display: block;
        }
    }

    .__check-icon {
        position: absolute;
        bottom: -0.5rem;
        right: -1rem;
    }

    .__stp-description {
        max-width: 34.375rem;
        padding: 0.5rem 0.5rem 0;
    }

    .__cell--detail-arrow {
        width: 18px;
        height: 18px;
    }

    .__cell--todo-icon {
        margin-top: 20px;
    }
}
