<form [formGroup]="actionsForm" class="u-w100p">
    <mat-button-toggle-group
        formControlName="role"
        class="u-w100p"
        [hideSingleSelectionIndicator]="true"
        [hideMultipleSelectionIndicator]="true"
    >
        <mat-button-toggle *ngFor="let option of moverRoleOptions" [disableRipple]="true" [value]="option.value">
            {{ option.labelResource | translate }}
        </mat-button-toggle>
    </mat-button-toggle-group>
</form>
