<app-todo
    *ngIf="move"
    [checked]="energyStop?.status === ServiceStatus.Submitted"
    [label]="'MOVE.METER_READING.INFO_COMPLETE_BY_ADMIN' | translate"
>
    <app-todo-template
        *todoStateProcessing
        [label]="'REGULARIZATION_BILL_PROCESSING' | i18nKeyTranslate: i18nKeys.ServiceAction"
    ></app-todo-template>
    <app-todo-template *todoStateChecked>
        <a app-button [appearance]="buttonAppearance.Link" [size]="buttonSize.Sm" (click)="downloadEDS()">{{ 'DOWNLOAD' | translate }}</a>
    </app-todo-template>
</app-todo>
