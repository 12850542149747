<app-card-container class="u-flex-row u-flex-justify-content-center">
    <div class="u-container-medium">
        <app-card *ngIf="showSuccess; else form">
            <app-empty-state [direction]="uiDirection.Horizontal">
                <app-svg-illustration [svg]="illustrations.EnergyBulb" [context]="uiContext.Accent"></app-svg-illustration>
                <h5>{{ 'ITS_A_WRAP' | translate }}</h5>
                <div empty-state-info>
                    <app-todo [label]="'ENERGY_OFFER_SENT' | i18nKeyTranslate: i18nKeys.RealEstateAgentTodo" [checked]="true">
                        <app-todo-template *todoStateChecked>
                            <span class="u-font-small u-color-muted">{{ 'BY_EMAIL' | translate}}</span>
                        </app-todo-template>
                    </app-todo>
                    <app-todo
                        *ngIf="energyStopStore.energyStop().states?.suppliersStatus !== EnergyStopSuppliersStatus.Complete && move?.linkedMove"
                        [label]="'LEAVER_SUPPLIERS_REQUESTED' | i18nKeyTranslate: i18nKeys.RealEstateAgentTodo"
                        [checked]="true"
                    >
                        <app-todo-template *todoStateChecked>
                            <span class="u-font-small u-color-muted">{{ 'BY_EMAIL' | translate}}</span>
                        </app-todo-template>
                    </app-todo>
                </div>
                <button app-button (click)="viewTransaction()">{{ 'VIEW_TRANSACTION' | translate }}</button>
            </app-empty-state>
        </app-card>
    </div>
</app-card-container>

<ng-template #form>
    <div class="u-container-medium">
        <h3 class="u-margin-bottom-double">{{ 'MOVE.REGISTER.TITLE' | translate }}</h3>
        <app-lead-passing-form (submitSuccess)="onSuccess($event)"></app-lead-passing-form>
    </div>
</ng-template>
