import { FeatureScope } from '@smooved/core';

export interface ServiceFeature {
    service: FeatureScope | string;
    label: string;
    hasPermission: boolean;
    canExecute: boolean;
    callback: () => void;
}

const serviceFeatureFactory = (service: FeatureScope, label: string): ServiceFeature => {
    return {
        service,
        label,
        hasPermission: false,
        canExecute: false,
        callback: (): void => {
            /** */
        },
    };
};

export const services = [serviceFeatureFactory(FeatureScope.Eots, 'MOVE.SERVICE.ENERGY'), serviceFeatureFactory(FeatureScope.Gift, 'GIFT')];
