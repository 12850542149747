import { Pipe, PipeTransform } from '@angular/core';
import { RealEstateAgent } from '@app/real-estate-agent/interfaces/real-estate-agent';
import { EnumUtils, RealEstateAgentState } from '@smooved/core';

@Pipe({
    name: 'realEstateAgentStateIcon',
})
export class RealEstateAgentStateIconPipe implements PipeTransform {
    public transform(value: Partial<RealEstateAgent>): string {
        if (!EnumUtils.values(RealEstateAgentState).includes(value?.state)) return '';
        return `https://assets.smooved.be/icons/state-${RealEstateAgentState[value.state].toLowerCase()}.svg`;
    }
}
