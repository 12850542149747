import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BaseTableComponent } from '@app/admin/components/base-table/base-table.component';
import { defaultColumns } from '@app/invoicing/components/base-energy-quarterly-table/base-energy-quarterly-table.constants';
import { BaseMovesDataSource } from '@app/move/datasources/base-moves/base-moves.data-source';
import { Move } from '@app/move/interfaces/move';
import moment from 'moment';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-base-energy-quarterly-table',
    template: `
        <app-card [bordered]="false" [paddingDouble]="false">
            <h6 class="u-padding u-bordered-bottom">{{ title }}</h6>
            <app-loading-container [loading]="dataSource.loading$ | async">
                <table mat-table [dataSource]="dataSource" class="u-w100p u-data-table-with-hover">
                    <app-table-column-formatted-name></app-table-column-formatted-name>

                    <app-table-column-moving-address></app-table-column-moving-address>

                    <app-table-column-moving-date [withSort]="false"></app-table-column-moving-date>

                    <app-table-column-energy-flow-type></app-table-column-energy-flow-type>

                    <app-table-column-fee></app-table-column-fee>

                    <app-table-column-ean-count></app-table-column-ean-count>

                    <app-table-column-detail-arrow></app-table-column-detail-arrow>

                    <!-- Row Declarations -->
                    <tr mat-header-row *matHeaderRowDef="columns" class="u-hide-up-to-and-including-phone-landscape"></tr>
                    <tr mat-row *matRowDef="let row; columns: columns" (click)="clickDetail.emit(row)"></tr>
                </table>
                <mat-paginator
                    [length]="dataSource.movesCount$ | async"
                    [pageSize]="pageSizeOptions[0]"
                    [showFirstLastButtons]="true"
                    [pageSizeOptions]="pageSizeOptions"
                ></mat-paginator>
            </app-loading-container>
        </app-card>
    `,
})
export class BaseEnergyQuarterlyTableComponent extends BaseTableComponent implements OnInit, OnChanges {
    @Input() public title: string;
    @Input() public dataSource: BaseMovesDataSource;
    @Input() public realEstateGroupId: string;
    @Input() public quarterDate: Date;

    @Output() public clickDetail: EventEmitter<Move> = new EventEmitter<Move>();

    public columns = defaultColumns;

    constructor(protected dialog: MatDialog) {
        super(dialog);
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.quarterDate.currentValue) {
            this.load();
        }
    }

    public ngOnInit(): void {
        super.ngOnInit();

        this.paginator.page.pipe(takeUntil(this.destroy$)).subscribe((_) => this.load());
    }

    public load(): void {
        this.dataSource.load(
            {
                realEstateGroup: this.realEstateGroupId,
                year: moment(this.quarterDate).year(),
                quarter: moment(this.quarterDate).quarter(),
            },
            {
                pageSize: this.currentPageSize(),
                pageIndex: this.currentPageIndex(),
            }
        );
    }
}
