import { Navigation } from '@app/navigation/enums/navigation.enum';
import { SettingsDetailsMenuItem } from './settings-details-menu.interface';
import { FeatureScope } from '@smooved/core';

export const menuItems = new Map<FeatureScope | string, SettingsDetailsMenuItem>()
    .set(FeatureScope.Reviews, {
        labelResource: 'REVIEWS.LABEL_PLURAL',
        goTo: ['..', 'modules', Navigation.Reviews],
        featureScope: FeatureScope.Reviews,
    })
    .set(FeatureScope.Gift, {
        labelResource: 'GIFTS.LABEL_PLURAL',
        goTo: ['..', 'modules', Navigation.Gifts],
        featureScope: FeatureScope.Gift,
        hide: FeatureScope.Transactions,
    })
    .set(FeatureScope.Mortgage, {
        labelResource: 'MORTGAGE.LABEL_PLURAL',
        goTo: ['..', 'modules', Navigation.Mortgage],
        featureScope: FeatureScope.Mortgage,
        hide: FeatureScope.Transactions,
    })
    .set(FeatureScope.Insurances, {
        labelResource: 'INSURANCES.LABEL_PLURAL',
        goTo: ['..', 'modules', Navigation.Insurances],
        featureScope: FeatureScope.Insurances,
        hide: FeatureScope.Transactions,
    })
    .set(FeatureScope.Water, {
        labelResource: 'WATER.LABEL',
        goTo: ['..', 'modules', Navigation.Water],
        featureScope: FeatureScope.Water,
        hide: FeatureScope.Transactions,
    })
    .set(FeatureScope.Tots, {
        labelResource: 'TOTS.LABEL',
        goTo: ['..', 'modules', Navigation.Tots],
        featureScope: FeatureScope.Tots,
        hide: FeatureScope.Transactions,
    });
