import { Pipe, PipeTransform } from '@angular/core';
import { RealEstateAgent } from '@app/real-estate-agent/interfaces/real-estate-agent';
import { RealEstateAgentState } from '@smooved/core';

@Pipe({
    name: 'agentStates',
})
export class AgentStatesPipe implements PipeTransform {
    public transform(value: Partial<RealEstateAgent>): string {
        switch (value.state) {
            case RealEstateAgentState.Active:
                return 'Active';
            case RealEstateAgentState.Inactive:
                return 'Inactive';
            case RealEstateAgentState.Draft:
                return 'Draft';
            default:
                return 'Draft';
        }
    }
}
