<app-todo
    [label]="
        'SUPPLIERS_ADDED'
            | i18nKeyTranslate
                : i18nKeys.ServiceAction
                : { name: (moverRole.Leaver | i18nKeyTranslate: i18nKeys.MoverRole).toLocaleLowerCase() }
    "
    [checked]="energyStop?.active && energyStop?.states.suppliersStatus === EnergyStopSuppliersStatus.Complete"
>
    <app-todo-template *todoStateUnchecked>
        <ui-dropdown-menu-button
            [label]="'ADD' | translate"
            [size]="buttonSize.Sm"
            [appearance]="buttonAppearance.Stroked"
            [context]="uiContext.Secondary"
        >
            <button mat-menu-item uiDropdownMenuItem (click)="updateSuppliers()">
                {{ 'ADD_BY_YOURSELF' | translate }}
            </button>
            <button mat-menu-item uiDropdownMenuItem (click)="resendInvite()">
                {{ 'RESEND_INVITE' | translate }}
            </button>
        </ui-dropdown-menu-button>
    </app-todo-template>
</app-todo>
